<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="container">
        loading...
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from '@ionic/vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    IonContent,
    IonPage
  },
  created() {
    this.query = this.$route.query
    this.register()
  },
  data () {
    return {
      query: null
    }
  },
  methods: {
    async register() {
      let refCode = this.query.by || null
      await localStorage.setItem("referral_by", refCode);
      this.$router.push({name: 'Auth'})
    }
  }
})
</script>

<style>

</style>
